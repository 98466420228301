import formValidator from "../form/formValidator"
import tagmanager from "../../abstracts/tagmanager";

const newsletter = () => ({
    ...formValidator(),

    afterInit() {
        // after user successfully completed submitting form: trigger reset of form
        this.$watch('open', (isModalOpen) => !isModalOpen && this.success && this.reset())
    },

    async submit() {
        let formData = {
            email: this.$refs.email.value,
            checkbox: this.$refs.checkbox.value
        }

        let data = await this.submitPostData('/presentation/api/newsletter/subscribe', formData)

        if (!data.error) {
            tagmanager.event('newsletter-widget-subscribe', {})
            this.$dispatch('success', this.$refs.email.value)
            this.$dispatch('newsletter-success', this.$refs.email.value)
        }
    }
})

export default newsletter